/* @jsx jsx */
import { jsx } from "theme-ui";
import React, { Fragment, useContext, useEffect } from "react";
import { Link, navigate } from "gatsby";
import { useLocation } from "@reach/router";
import Notifications, { notify } from "react-notify-toast";
import Providers from "./Providers";
import { FeedbackForm } from "../../../npm/src/FeedbackForm";
import {
  GetCurrentUserQuery,
  useCreateProjectMutation,
  useGetCurrentUserQuery,
} from "../graphql/queries/_generated";
import config from "../config";
import { Footer } from "../pages";
import { useCheckoutHandler } from "./Checkout";
import { ReactComponent as Logo } from "../../static/logo.svg";
import { Nav, NavContent, NavLink } from "./Nav";
import theme from "../gatsby-plugin-theme-ui";
import { ProjectPicker } from "./ProjectPicker";
import tw from "twin.macro";
import { ReactComponent as Arrow } from "../../static/arrow.svg";
import { OperationContext } from "urql";
import { splitbee } from "../splitbee";

export const linkButtonStyle = {
  bg: "white",
  border: `none`,
  textDecoration: `none`,
  color: `gray.8`,
  fontWeight: `500`,
  fontSize: 1,
  display: `block`,
  cursor: `pointer`,
  fontFamily: "Roboto",
};

export const NavButton: React.FC<{ href?: string }> = (props) =>
  jsx(props.href ? "a" : "button", {
    sx: linkButtonStyle,
    ...props,
  });

const WidgetCTAContext = React.createContext<string | void>(undefined);
const FeedbackButton: React.FC = (props) => {
  const widgetCTA = useContext(WidgetCTAContext);

  return (
    <div sx={{ position: `relative` }}>
      <NavLink to="#" {...props}>
        Give us feedback
      </NavLink>
      {widgetCTA && (
        <Fragment>
          <Arrow
            sx={{
              position: "absolute",
              right: 0,
              transform: ` translateX(50%) rotate(180deg)`,
              top: `100%`,
              height: `61px`,
              display: [`none`, `none`, `block`, `block`],
            }}
          />
          <div
            sx={{
              position: "absolute",
              right: 0,
              transform: ` translateX(50%)`,
              top: `calc(100% + 61px)`,
              color: `#FF6A00`,
              fontSize: 2,
              fontFamily: `Bradley Hand, Comic Sans MS, cursive`,
              textAlign: `center`,
              overflowX: `visible`,
              whiteSpace: `nowrap`,
              display: [`none`, `none`, `block`, `block`],
            }}
          >
            {widgetCTA}
          </div>
        </Fragment>
      )}
    </div>
  );
};

const getProjectId = (location: Location): string | null => {
  const match = location.pathname.match(/\/app\/(\w+)\/?/i);
  if (!match || !match[1]) return null;

  return match[1];
};

const Navbar = ({
  currentUser,
  refetchCurrentUser,
  widgetCTA,
}: {
  currentUser: GetCurrentUserQuery["currentUser"];
  widgetCTA?: string;
  refetchCurrentUser: (opts?: Partial<OperationContext> | undefined) => void;
}) => {
  const location = useLocation();
  const currentProjectId = getProjectId(location);

  const [, createProject] = useCreateProjectMutation();

  useEffect(() => {
    let t: NodeJS.Timeout;
    if (currentUser?.email) {
      t = setTimeout(() => {
        (window as any).splitbee?.user.set({
          email: currentUser?.email,
        });
      }, 1000);
    }

    return () => {
      if (t) {
        clearTimeout(t);
      }
    };
  }, [currentUser]);

  const loggedIn = !!currentUser;

  return (
    <Nav>
      <Link
        to={loggedIn ? `/app/${currentProjectId || ""}` : "/"}
        sx={{ display: "flex" }}
      >
        <Logo sx={{ height: 8, my: 1 }} />
      </Link>

      <div sx={{ ...tw`z-10 w-full px-3 md:max-w-xs`, minWidth: 100 }}>
        {currentProjectId && currentUser?.projects && (
          <ProjectPicker
            currentProjectId={currentProjectId}
            projects={currentUser.projects || []}
            onProjectSwitch={(id) => navigate(`/app/${id}`)}
            onOpenSettings={(id) => navigate(`/app/${id}/settings`)}
            onNewProject={() => {
              createProject().then(async ({ data }) => {
                if (!data?.createProject?.id) return;

                await refetchCurrentUser();
                navigate(`/app/${data.createProject.id}`);
              });
            }}
          />
        )}
      </div>

      <NavContent>
        <WidgetCTAContext.Provider value={widgetCTA}>
          <FeedbackForm
            projectId={
              process.env.NODE_ENV === "development"
                ? currentProjectId || ""
                : "f7c9b9b0b1002e"
            }
            userId={currentUser?.email}
            triggerComponent={FeedbackButton}
          />
        </WidgetCTAContext.Provider>
        <NavLink to={`/help`}>Help</NavLink>

        {currentUser?.email && (
          <NavLink to={`${config.backendUrl}/auth/logout`}>Logout</NavLink>
        )}
      </NavContent>
    </Nav>
  );
};

type Props = {
  disableHeader?: boolean;
  disablePadding?: boolean;
  minimalFooter?: boolean;
  centered?: boolean;
  wide?: boolean;
  widgetCTA?: string;
};

const Page: React.FC<Props> = ({
  children,
  disableHeader,
  disablePadding,
  centered = true,
  minimalFooter,
  wide = false,
  widgetCTA,
}) => {
  const [{ data }, refetchCurrentUser] = useGetCurrentUserQuery();
  const email = data?.currentUser?.email;

  useEffect(() => {
    if (email) {
      splitbee()?.user.set({
        email,
      });
    }
  }, [email, splitbee()]);

  return (
    <Providers>
      <div
        sx={{
          maxWidth: (theme) => [
            theme.breakpoints[0],
            ...(wide ? theme.breakpoints : theme.breakpoints.slice(0, -1)),
          ],
          margin: "auto",
          display: `flex`,
          flexDirection: `column`,
          minHeight: `100vh`,
        }}
      >
        {!disableHeader && (
          <Navbar
            currentUser={data?.currentUser}
            refetchCurrentUser={refetchCurrentUser}
            widgetCTA={widgetCTA}
          />
        )}
        <Notifications
          options={{
            colors: {
              success: {
                background: theme.colors.green[2],
                color: theme.colors.green[6],
              },
              warning: {
                backgroundColor: theme.colors.orange[2],
                color: theme.colors.orange[6],
              },
              error: {
                backgroundColor: theme.colors.red[2],
                color: theme.colors.red[6],
              },
            },
          }}
        />
        <main
          sx={{
            px: disablePadding ? 0 : 3,
            flex: 1,
            ...(centered
              ? {
                  display: `flex`,
                  flexDirection: `column`,
                  justifyContent: `center`,
                }
              : {}),
          }}
        >
          {children}
        </main>
        <Footer minimal={!!minimalFooter} />
      </div>
    </Providers>
  );
};

export const Layout = (props: Props) => (
  <Providers>
    <Page {...props} />
  </Providers>
);
