/* @jsx jsx */
import { jsx } from "theme-ui";
// @ts-ignore
import { ReactComponent as CheckmarkIcon } from "../../static/checkmark.svg";
import { ReactComponent as CircleQuarter } from "../../static/circle-quarter.svg";
import { Button } from "./Button";

const Feature: React.FC<{
  color: string;
}> = ({ children, color }) => (
  <div
    sx={{
      display: "flex",
      mb: 2,
    }}
  >
    <CheckmarkIcon
      fill={
        color === "orange" ? "#FF6A00" : color === "blue" ? "#005DFF" : color
      }
      width={16}
    />
    <div
      sx={{
        fontSize: 2,
        fontWeight: "500",
        lineHeight: 1,
        ml: "2",
      }}
    >
      {children}
    </div>
  </div>
);

const Pricing: React.FC<{}> = () => {
  return (
    <div sx={{ justifyContent: "center" }}>
      <div
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          sx={{
            maxWidth: "700px",
            bg: "brandBlue.0",
            color: "brandBlue.4",
            py: [5, 4],
            borderTopLeftRadius: 96,
            position: "relative",
            width: "100%",
          }}
        >
          <div
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <CircleQuarter fill={"#005DFF"} />
            <CircleQuarter fill={"#99BEFF"} />
          </div>

          <div
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              paddingX: [4, 5],
              justifyContent: "center",
            }}
          >
            <div
              sx={{
                color: "brandBlue.2",
                fontSize: [4, 5],
                fontWeight: "bold",
                lineHeight: 1,
                fontFamily: "PT Serif",
                mb: 3,
              }}
            >
              Free
            </div>
            <div
              sx={{
                fontSize: 2,
                fontWeight: "500",
                lineHeight: 1,
                mb: 3,
                paddingRight: [5, 4],
              }}
            >
              Perfect to start collecting feedback for your app
            </div>
            <Feature color="blue">25 total feedback submissions</Feature>
            <div sx={{ display: ["none", "block"], mt: 3 }}>
              <Button
                href={`/login`}
                data-splitbee-event="Click CTA"
                data-splitbee-event-location="pricing"
                data-splitbee-event-pricing="free"
                primary
                sx={{ lineHeight: 1 }}
              >
                Get started
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div sx={{ mt: [0, 3], justifyContent: "center" }}>
        <div
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            sx={{
              maxWidth: "700px",
              bg: "brandOrange.0",
              color: "brandOrange.4",
              py: [5, 4],
              position: "relative",
              width: "100%",
            }}
          >
            <div
              sx={{
                position: "absolute",
                right: 0,
                top: 0,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <CircleQuarter fill={"#FF6A00"} />
              <CircleQuarter fill={"#FFD2B3"} />
            </div>

            <div
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                paddingX: [4, 5],

                justifyContent: "center",
              }}
            >
              <div
                sx={{
                  display: "flex",
                  fontFamily: "PT Serif",
                  color: "brandOrange.3",
                  mb: 3,
                }}
              >
                <div
                  sx={{
                    fontSize: [4, 5],
                    fontWeight: "bold",
                    lineHeight: 1,
                  }}
                >
                  $19
                </div>
                <div sx={{ marginTop: "auto" }}>/month</div>
              </div>

              <div
                sx={{
                  fontSize: 2,
                  fontWeight: "500",
                  lineHeight: 1,
                  mb: 3,
                  paddingRight: [5, 4],
                }}
              >
                Suitable for production applications and websites of all sizes
              </div>
              <Feature color="orange">Unlimited feedback submissions</Feature>

              <div sx={{ display: ["none", "block"], mt: 3 }}>
                <Button
                  primary
                  href={`/login`}
                  data-splitbee-event="Click CTA"
                  data-splitbee-event-location="pricing"
                  data-splitbee-event-pricing="paid"
                  sx={{
                    bg: "brandOrange.3",
                    lineHeight: 1,
                    ":hover": {
                      backgroundColor: "brandOrange.4",
                    },
                  }}
                >
                  Get started
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div sx={{ mt: [0, 3], justifyContent: "center" }}>
        <div
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            sx={{
              maxWidth: "700px",
              bg: "#e5fbed",
              color: "#1ea251",
              py: [5, 4],
              borderBottomRightRadius: 96,
              position: "relative",
              width: "100%",
            }}
          >
            <div
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <CircleQuarter fill={"#1ea251"} />
              <CircleQuarter fill={"#82e8a9"} />
            </div>

            <div
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                paddingX: [4, 5],

                justifyContent: "center",
              }}
            >
              <div
                sx={{
                  display: "flex",
                  fontFamily: "PT Serif",
                  color: "#1ea251",
                  mb: 3,
                }}
              >
                <div
                  sx={{
                    fontSize: [4, 5],
                    fontWeight: "bold",
                    lineHeight: 1,
                  }}
                >
                  Enterprise
                </div>
              </div>

              <div
                sx={{
                  fontSize: 2,
                  fontWeight: "500",
                  lineHeight: 1,
                  mb: 3,
                  paddingRight: [5, 4],
                }}
              >
                Talk to us about your custom needs
              </div>
              <Feature color="#70e79e">Whitelabeling</Feature>
              <Feature color="#70e79e">Service-level agreements</Feature>
              <Feature color="#70e79e">Live chat support</Feature>

              <div sx={{ display: ["none", "block"], mt: 3 }}>
                <Button
                  primary
                  href="mailto:info@feedback.fish"
                  data-splitbee-event="Click CTA"
                  data-splitbee-event-location="pricing"
                  data-splitbee-event-pricing="enterprise"
                  sx={{
                    bg: "#1ea251",
                    lineHeight: 1,
                    ":hover": {
                      backgroundColor: "#16773b",
                    },
                  }}
                >
                  Contact us
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Pricing;
