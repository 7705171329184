/* @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";

type Colors =
  | "red"
  | "yellow"
  | "green"
  | "orange"
  | "teal"
  | "gray"
  | "white"
  | "lightGray"
  | "purple";
const currentColor = (color: Colors) => {
  switch (color) {
    case "red":
      return "#F56565";
    case "yellow":
      return "#ECC94B";
    case "green":
      return "#68D391";
    case "orange":
      return "#F6AD55";
    case "teal":
      return "#4FD1C5";
    case "purple":
      return "#B794F4";
    case "gray":
      return "#9EB3D6";
    case "lightGray":
      return "#6C7D9B";
    case "white":
      return "#ffff";
  }
};
export const Line: React.FC<{ number: number }> = ({ children, number }) => {
  return (
    <div
      sx={{
        display: "flex",
      }}
    >
      <Code color="lightGray" selectable={false} offset={1}>
        {number}
      </Code>
      {children}
    </div>
  );
};
export const Code: React.FC<{
  color: Colors;
  inset?: number;
  offset?: number;
  selectable?: boolean;
}> = ({ children, color, inset = 0, offset = 0, selectable = true }) => {
  const textColor = currentColor(color);
  return (
    <span
      sx={{
        fontFamily: "Source Code Pro",
        color: textColor,
        userSelect: selectable ? "initial" : "none",
        fontSize: 14,
        whiteSpace: "nowrap",
        marginLeft: inset * 8 + "px",
        marginRight: offset * 8 + "px",
      }}
    >
      {children}
    </span>
  );
};
export const CodeSnippet: React.FC = ({ children }) => {
  return (
    <div
      sx={{
        display: "flex",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <div
        sx={{
          bg: "brandBlue.5",
          borderRadius: 8,
          width: "100%",
          paddingY: "8px",
          border: "1px solid ",
          borderColor: "brandBlue.4",
          paddingLeft: "12px",
          overflow: "hidden",
        }}
      >
        {children}
      </div>
    </div>
  );
};
