/* @jsx jsx */
import { jsx } from "theme-ui";
import {
  cacheExchange,
  createClient,
  dedupExchange,
  fetchExchange,
  Provider,
} from "urql";
import React from "react";
import config from "../config";
import { pipe, tap } from "wonka";
import { Exchange } from "urql";
import { notify } from "react-notify-toast";

export const errorExchange: Exchange = ({ forward }) => (ops$) => {
  return pipe(
    forward(ops$),
    tap(({ error }) => {
      if (error) {
        notify.show(error.message.replace("[GraphQL] ", ""), "error");
      }
    })
  );
};

const client = createClient({
  url: config.graphqlUrl,
  exchanges: [dedupExchange, cacheExchange, errorExchange, fetchExchange],
  fetchOptions: {
    credentials: `include`,
  },
});

export default function Providers({ children }: { children: React.ReactNode }) {
  return <Provider value={client}>{children}</Provider>;
}
