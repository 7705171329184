import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export type Bot = {
  __typename?: 'Bot';
  name: Scalars['String'];
  category: Scalars['String'];
  url: Scalars['String'];
};

export enum Categories {
  Issue = 'issue',
  Idea = 'idea',
  Other = 'other'
}

export type Checkout = {
  __typename?: 'Checkout';
  id: Scalars['String'];
};

export type Client = {
  __typename?: 'Client';
  type: Scalars['String'];
  name: Scalars['String'];
  version: Scalars['String'];
  engine: Scalars['String'];
  engineVersion: Scalars['String'];
};


export type Device = {
  __typename?: 'Device';
  client?: Maybe<Client>;
  bot?: Maybe<Bot>;
  device?: Maybe<DeviceType>;
  os?: Maybe<Os>;
};

export type DeviceType = {
  __typename?: 'DeviceType';
  type: Scalars['String'];
  brand: Scalars['String'];
  model: Scalars['String'];
};

export type Feedback = {
  __typename?: 'Feedback';
  id: Scalars['Float'];
  text: Scalars['String'];
  screenshotUrl?: Maybe<Scalars['String']>;
  category: Categories;
  device: Device;
  location?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  metadata?: Maybe<Array<KeyValue>>;
  status: Status;
  createdAt: Scalars['DateTime'];
  project?: Maybe<Array<Project>>;
};

export type KeyValue = {
  __typename?: 'KeyValue';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  updateFeedback?: Maybe<Feedback>;
  createCheckout?: Maybe<Checkout>;
  getCheckoutSettingsUrl: Scalars['String'];
  createProject?: Maybe<Project>;
  updateProject?: Maybe<Project>;
  inviteUserToProject: Project;
  sendWidgetInstructions: Scalars['Boolean'];
  toggleEmailFeedbackNotifications: ProjectUserEdge;
};


export type MutationUpdateFeedbackArgs = {
  status: Status;
  id: Scalars['Float'];
};


export type MutationCreateCheckoutArgs = {
  plan?: Maybe<Scalars['String']>;
  projectId: Scalars['String'];
};


export type MutationGetCheckoutSettingsUrlArgs = {
  projectId: Scalars['String'];
  returnUrl: Scalars['String'];
};


export type MutationUpdateProjectArgs = {
  name?: Maybe<Scalars['String']>;
  projectId: Scalars['String'];
};


export type MutationInviteUserToProjectArgs = {
  projectId: Scalars['String'];
  email: Scalars['String'];
};


export type MutationSendWidgetInstructionsArgs = {
  projectId: Scalars['String'];
  email: Scalars['String'];
};


export type MutationToggleEmailFeedbackNotificationsArgs = {
  projectId: Scalars['String'];
  userId: Scalars['String'];
};

export type Os = {
  __typename?: 'OS';
  name: Scalars['String'];
  version: Scalars['String'];
  platform: Scalars['String'];
};

export type Project = {
  __typename?: 'Project';
  id: Scalars['String'];
  usersConnection: ProjectUserConnection;
  name: Scalars['String'];
  isPaying: Scalars['Boolean'];
  paidPlan?: Maybe<Scalars['String']>;
  pricePerMonth: Scalars['Float'];
  invitations?: Maybe<Array<ProjectInvitation>>;
  users?: Maybe<Array<User>>;
};

export type ProjectInvitation = {
  __typename?: 'ProjectInvitation';
  id: Scalars['String'];
  email: Scalars['String'];
  createdAt: Scalars['DateTime'];
};

export type ProjectUserConnection = {
  __typename?: 'ProjectUserConnection';
  edges: Array<ProjectUserEdge>;
};

export type ProjectUserEdge = {
  __typename?: 'ProjectUserEdge';
  node: User;
  emailFeedbackNotifications: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  feedback: Array<Feedback>;
  currentUser?: Maybe<User>;
  project?: Maybe<Project>;
};


export type QueryFeedbackArgs = {
  projectId: Scalars['String'];
};


export type QueryProjectArgs = {
  id: Scalars['String'];
};

export enum Status {
  Archived = 'archived',
  New = 'new'
}

export type User = {
  __typename?: 'User';
  id: Scalars['String'];
  email: Scalars['String'];
  projects: Array<Project>;
};

export type CreateCheckoutMutationVariables = Exact<{
  projectId: Scalars['String'];
  plan?: Maybe<Scalars['String']>;
}>;


export type CreateCheckoutMutation = (
  { __typename?: 'Mutation' }
  & { createCheckout?: Maybe<(
    { __typename?: 'Checkout' }
    & Pick<Checkout, 'id'>
  )> }
);

export type CreateProjectMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateProjectMutation = (
  { __typename?: 'Mutation' }
  & { createProject?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'isPaying'>
  )> }
);

export type GetCheckoutSettingsUrlMutationVariables = Exact<{
  projectId: Scalars['String'];
  returnUrl: Scalars['String'];
}>;


export type GetCheckoutSettingsUrlMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'getCheckoutSettingsUrl'>
);

export type InviteUserToProjectMutationVariables = Exact<{
  projectId: Scalars['String'];
  email: Scalars['String'];
}>;


export type InviteUserToProjectMutation = (
  { __typename?: 'Mutation' }
  & { inviteUserToProject: (
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'isPaying'>
    & { invitations?: Maybe<Array<(
      { __typename?: 'ProjectInvitation' }
      & Pick<ProjectInvitation, 'createdAt' | 'email'>
    )>>, users?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email'>
    )>> }
  ) }
);

export type SendWidgetInstructionsMutationVariables = Exact<{
  projectId: Scalars['String'];
  email: Scalars['String'];
}>;


export type SendWidgetInstructionsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendWidgetInstructions'>
);

export type ToggleEmailFeedbackNotificationsMutationVariables = Exact<{
  projectId: Scalars['String'];
  userId: Scalars['String'];
}>;


export type ToggleEmailFeedbackNotificationsMutation = (
  { __typename?: 'Mutation' }
  & { toggleEmailFeedbackNotifications: (
    { __typename?: 'ProjectUserEdge' }
    & Pick<ProjectUserEdge, 'emailFeedbackNotifications'>
    & { node: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email'>
    ) }
  ) }
);

export type UpdateFeedbackMutationVariables = Exact<{
  id: Scalars['Float'];
  status: Status;
}>;


export type UpdateFeedbackMutation = (
  { __typename?: 'Mutation' }
  & { updateFeedback?: Maybe<(
    { __typename?: 'Feedback' }
    & Pick<Feedback, 'id' | 'text' | 'status' | 'category' | 'createdAt' | 'location' | 'userId'>
    & { metadata?: Maybe<Array<(
      { __typename?: 'KeyValue' }
      & Pick<KeyValue, 'key' | 'value'>
    )>>, device: (
      { __typename?: 'Device' }
      & { client?: Maybe<(
        { __typename?: 'Client' }
        & Pick<Client, 'name' | 'version'>
      )>, os?: Maybe<(
        { __typename?: 'OS' }
        & Pick<Os, 'name' | 'version'>
      )> }
    ) }
  )> }
);

export type UpdateProjectMutationVariables = Exact<{
  projectId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
}>;


export type UpdateProjectMutation = (
  { __typename?: 'Mutation' }
  & { updateProject?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'isPaying'>
  )> }
);

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = (
  { __typename?: 'Query' }
  & { currentUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email'>
    & { projects: Array<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'name' | 'isPaying'>
    )> }
  )> }
);

export type GetFeedbackQueryVariables = Exact<{
  projectId: Scalars['String'];
}>;


export type GetFeedbackQuery = (
  { __typename?: 'Query' }
  & { feedback: Array<(
    { __typename?: 'Feedback' }
    & Pick<Feedback, 'id' | 'text' | 'status' | 'category' | 'createdAt' | 'location' | 'userId' | 'screenshotUrl'>
    & { project?: Maybe<Array<(
      { __typename?: 'Project' }
      & Pick<Project, 'id'>
    )>>, metadata?: Maybe<Array<(
      { __typename?: 'KeyValue' }
      & Pick<KeyValue, 'key' | 'value'>
    )>>, device: (
      { __typename?: 'Device' }
      & { client?: Maybe<(
        { __typename?: 'Client' }
        & Pick<Client, 'name' | 'version'>
      )>, os?: Maybe<(
        { __typename?: 'OS' }
        & Pick<Os, 'name' | 'version'>
      )> }
    ) }
  )> }
);

export type GetProjectQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetProjectQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'isPaying' | 'paidPlan' | 'pricePerMonth'>
    & { invitations?: Maybe<Array<(
      { __typename?: 'ProjectInvitation' }
      & Pick<ProjectInvitation, 'createdAt' | 'email'>
    )>>, usersConnection: (
      { __typename?: 'ProjectUserConnection' }
      & { edges: Array<(
        { __typename?: 'ProjectUserEdge' }
        & Pick<ProjectUserEdge, 'emailFeedbackNotifications'>
        & { node: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email'>
        ) }
      )> }
    ) }
  )> }
);


export const CreateCheckoutDocument = gql`
    mutation createCheckout($projectId: String!, $plan: String) {
  createCheckout(projectId: $projectId, plan: $plan) {
    id
  }
}
    `;

export function useCreateCheckoutMutation() {
  return Urql.useMutation<CreateCheckoutMutation, CreateCheckoutMutationVariables>(CreateCheckoutDocument);
};
export const CreateProjectDocument = gql`
    mutation createProject {
  createProject {
    id
    name
    isPaying
  }
}
    `;

export function useCreateProjectMutation() {
  return Urql.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CreateProjectDocument);
};
export const GetCheckoutSettingsUrlDocument = gql`
    mutation getCheckoutSettingsUrl($projectId: String!, $returnUrl: String!) {
  getCheckoutSettingsUrl(projectId: $projectId, returnUrl: $returnUrl)
}
    `;

export function useGetCheckoutSettingsUrlMutation() {
  return Urql.useMutation<GetCheckoutSettingsUrlMutation, GetCheckoutSettingsUrlMutationVariables>(GetCheckoutSettingsUrlDocument);
};
export const InviteUserToProjectDocument = gql`
    mutation inviteUserToProject($projectId: String!, $email: String!) {
  inviteUserToProject(projectId: $projectId, email: $email) {
    id
    name
    isPaying
    invitations {
      createdAt
      email
    }
    users {
      id
      email
    }
  }
}
    `;

export function useInviteUserToProjectMutation() {
  return Urql.useMutation<InviteUserToProjectMutation, InviteUserToProjectMutationVariables>(InviteUserToProjectDocument);
};
export const SendWidgetInstructionsDocument = gql`
    mutation sendWidgetInstructions($projectId: String!, $email: String!) {
  sendWidgetInstructions(projectId: $projectId, email: $email)
}
    `;

export function useSendWidgetInstructionsMutation() {
  return Urql.useMutation<SendWidgetInstructionsMutation, SendWidgetInstructionsMutationVariables>(SendWidgetInstructionsDocument);
};
export const ToggleEmailFeedbackNotificationsDocument = gql`
    mutation toggleEmailFeedbackNotifications($projectId: String!, $userId: String!) {
  toggleEmailFeedbackNotifications(projectId: $projectId, userId: $userId) {
    emailFeedbackNotifications
    node {
      id
      email
    }
  }
}
    `;

export function useToggleEmailFeedbackNotificationsMutation() {
  return Urql.useMutation<ToggleEmailFeedbackNotificationsMutation, ToggleEmailFeedbackNotificationsMutationVariables>(ToggleEmailFeedbackNotificationsDocument);
};
export const UpdateFeedbackDocument = gql`
    mutation updateFeedback($id: Float!, $status: Status!) {
  updateFeedback(id: $id, status: $status) {
    id
    text
    status
    category
    createdAt
    location
    userId
    metadata {
      key
      value
    }
    device {
      client {
        name
        version
      }
      os {
        name
        version
      }
    }
  }
}
    `;

export function useUpdateFeedbackMutation() {
  return Urql.useMutation<UpdateFeedbackMutation, UpdateFeedbackMutationVariables>(UpdateFeedbackDocument);
};
export const UpdateProjectDocument = gql`
    mutation updateProject($projectId: String!, $name: String) {
  updateProject(projectId: $projectId, name: $name) {
    id
    name
    isPaying
  }
}
    `;

export function useUpdateProjectMutation() {
  return Urql.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(UpdateProjectDocument);
};
export const GetCurrentUserDocument = gql`
    query getCurrentUser {
  currentUser {
    id
    email
    projects {
      id
      name
      isPaying
    }
  }
}
    `;

export function useGetCurrentUserQuery(options: Omit<Urql.UseQueryArgs<GetCurrentUserQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetCurrentUserQuery>({ query: GetCurrentUserDocument, ...options });
};
export const GetFeedbackDocument = gql`
    query getFeedback($projectId: String!) {
  feedback(projectId: $projectId) {
    id
    text
    status
    category
    createdAt
    location
    userId
    screenshotUrl
    project {
      id
    }
    metadata {
      key
      value
    }
    device {
      client {
        name
        version
      }
      os {
        name
        version
      }
    }
  }
}
    `;

export function useGetFeedbackQuery(options: Omit<Urql.UseQueryArgs<GetFeedbackQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetFeedbackQuery>({ query: GetFeedbackDocument, ...options });
};
export const GetProjectDocument = gql`
    query getProject($id: String!) {
  project(id: $id) {
    id
    name
    isPaying
    paidPlan
    pricePerMonth
    invitations {
      createdAt
      email
    }
    usersConnection {
      edges {
        emailFeedbackNotifications
        node {
          id
          email
        }
      }
    }
  }
}
    `;

export function useGetProjectQuery(options: Omit<Urql.UseQueryArgs<GetProjectQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetProjectQuery>({ query: GetProjectDocument, ...options });
};