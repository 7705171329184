/* @jsx jsx */
import React from "react";
import { jsx } from "theme-ui";
import tw from "twin.macro";
import { OverlayTrigger } from "./OverlayTrigger";
import { ReactComponent as Selector } from "heroicons/solid/selector.svg";
import { ReactComponent as Cog } from "heroicons/outline/cog.svg";
import { ReactComponent as Plus } from "heroicons/outline/plus.svg";

interface ProjectOptionProps {
  title: string;
  onClick: () => void;
  onOpenSettings: () => void;
}

export const ProjectOption: React.FC<ProjectOptionProps> = ({
  title,
  onClick,
  onOpenSettings,
}) => (
  <div sx={tw`flex overflow-hidden hover:bg-gray-200`}>
    <button
      sx={tw`flex-1 px-3 py-2 text-left truncate focus:outline-none focus:bg-gray-100`}
      onClick={onClick}
    >
      {title}
    </button>
    <button
      sx={tw`p-2 focus:outline-none focus:bg-gray-100`}
      onClick={onOpenSettings}
    >
      <Cog sx={tw`w-5 text-gray-500 hover:text-gray-600`} />
    </button>
  </div>
);

interface ProjectPickerProps {
  projects: Array<{
    name: string;
    id: string;
  }>;
  currentProjectId: string | null;
  onNewProject: () => void;
  onProjectSwitch: (projectId: string) => void;
  onOpenSettings: (projectId: string) => void;
}

export const ProjectPicker: React.FC<ProjectPickerProps> = ({
  currentProjectId,
  projects,
  onNewProject,
  onProjectSwitch,
  onOpenSettings,
}) => {
  const currentProject = projects.find((p) => p.id === currentProjectId);

  return (
    <OverlayTrigger
      renderContent={(close) => (
        <div>
          {projects.map((p) => (
            <ProjectOption
              key={p.id}
              title={p.name}
              onClick={() => {
                close();
                onProjectSwitch(p.id);
              }}
              onOpenSettings={() => {
                close();
                onOpenSettings(p.id);
              }}
            />
          ))}
          <button
            sx={tw`flex items-center justify-between w-full p-2 pl-3 text-left bg-gray-100 border-t hover:bg-gray-200 focus:outline-none focus:bg-gray-200`}
            onClick={() => {
              close();
              onNewProject();
            }}
          >
            <span sx={tw`text-gray-700`}>New Project</span>
            <Plus sx={tw`w-5 ml-2 text-gray-500`} />
          </button>
        </div>
      )}
      renderTrigger={(props) => (
        <button
          sx={tw`flex items-center justify-between w-full py-1 pl-3 pr-2 bg-gray-100 rounded focus:outline-none focus:bg-gray-200 hover:bg-gray-200`}
          {...props}
        >
          <span
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {currentProject?.name}
          </span>
          <Selector sx={tw`w-5 text-gray-600`} />
        </button>
      )}
    />
  );
};
