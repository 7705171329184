/* @jsx jsx */
import { jsx } from "theme-ui";
import { DialogContent, DialogOverlay } from "@reach/dialog";

interface Props {
  isOpen: boolean;
  onDismiss: () => void;
  className?: string;
  children: React.ReactNode;
}

export default (props: Props) => (
  <DialogOverlay
    sx={{ zIndex: 10 }}
    isOpen={props.isOpen}
    onDismiss={props.onDismiss}
  >
    <DialogContent
      sx={{
        width: [`100vw`, `100vw`, `45em`],
        height: [`100vh`, `100vh`, `auto`],
        position: [`fixed`, `fixed`, `relative`],
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        mx: [0, 0, "auto"],
        my: [0, 0, "10vh"],
        overflow: `hidden`,
        p: 0,
      }}
      className={props.className}
    >
      {props.children}
    </DialogContent>
  </DialogOverlay>
);
