/* @jsx jsx */
import React, { Fragment } from "react";
import { jsx } from "theme-ui";
import { ReactComponent as ChevronDown } from "heroicons/outline/chevron-down.svg";
import { ReactComponent as ChevronRight } from "heroicons/outline/chevron-right.svg";
import { Flex } from "../Flex";
import Link from "../Link";
import theme from "../../gatsby-plugin-theme-ui";
import tw from "twin.macro";

export const Nav: React.FC<{}> = ({ children }) => (
  <nav
    sx={{
      py: 4,
      px: 3,
      width: "100%",
      margin: "auto",
      display: "flex",
      alignItems: `center`,
      position: `relative`,
      zIndex: 10,
    }}
  >
    {children}
  </nav>
);

export const NavLink: React.FC<{ to: string }> = ({
  children,
  to,
  ...rest
}) => {
  return (
    <Link
      {...rest}
      to={to}
      sx={{
        display: `flex`,
        justifyContent: `space-between`,
        alignItems: `center`,
        color: "gray.7",
        fontWeight: `500`,
        textDecoration: "none",
        padding: ["16px 0px", "0"],
        borderRadius: "4px",
        lineHeight: "normal",
        cursor: "pointer",
        borderBottom: [`1px solid ${theme.colors.gray[2]}`, `none`],
        width: [`100%`, `initial`],
        ":hover": {
          color: "gray.8",
        },
        "&:last-of-type": {
          borderBottom: `none`,
        },
        svg: {
          transition: `transform 100ms ease-in-out`,
        },
        "&:hover svg": {
          transform: `translateX(2px)`,
        },
      }}
    >
      {children}
      <div sx={{ height: "1em", display: [`block`, `none`] }}>
        <ChevronRight sx={tw`w-6 mr-2 text-gray-600`} />
      </div>
    </Link>
  );
};

export const NavContent: React.FC<{}> = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <Fragment>
      {/* Desktop */}
      <Flex
        sx={{ display: ["none", "flex", "flex"], width: `100%` }}
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-end"
        gap={4}
      >
        {children}
      </Flex>

      {/* Mobile */}
      <Flex
        sx={{
          display: ["flex", "none", "none"],
        }}
        flexDirection="column"
        alignItems="flex-end"
      >
        <button
          sx={tw`p-2 m-0 text-gray-600 bg-transparent focus:outline-none hover:text-gray-700`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <ChevronDown
            sx={tw`w-6`}
            style={{
              transform: `rotate(${isOpen ? `180deg` : `0deg`})`,
              transition: `transform 100ms ease-in-out`,
            }}
            color="currentColor"
          />
        </button>
        <div
          onClick={() => setIsOpen(false)}
          sx={{
            display: isOpen ? `block` : `none`,
            position: "absolute",
            top: "100%",
            width: "100%",
            height: "100vh",
            left: "0",
            background: "rgba(0, 0, 0, 0.1)",
          }}
        >
          <Flex
            sx={{
              position: `absolute`,
              background: `white`,
              top: `-24px`,
              flexDirection: "column",
              width: `100%`,
              alignItems: `flex-start`,
              py: 2,
              px: 3,
            }}
          >
            {children}
          </Flex>
        </div>
      </Flex>
    </Fragment>
  );
};
