/* @jsx jsx */
import { jsx } from "theme-ui";
import { Link as GatsbyLink } from "gatsby";

const Link: React.FC<{
  to: string;
  className?: string;
  [key: string]: any;
}> = ({ children, to, ...other }) => {
  const internal = /^\/(?!\/)/.test(to);

  if (internal) {
    return (
      <GatsbyLink to={to} {...other}>
        {children}
      </GatsbyLink>
    );
  }
  return (
    <a href={to} {...other}>
      {children}
    </a>
  );
};
export default Link;
