import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";

interface SEOProps {
  title?: string;
  description?: string;
  image?: string;
}

const defaultTitle =
  "Feedback Fish: Collect feedback. Make your customers happy.";
const defaultDescription =
  "Collect issues, ideas and complements with a simple widget. Integrate it in minutes.";
const siteUrl = `https://feedback.fish`;
const twitterUsername = `@feedbackfish`;
const defaultImage = `/social-media.png`;

const SEO = ({ title, description, image }: SEOProps) => {
  const { pathname } = useLocation();

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  };

  return (
    <Helmet defaultTitle={defaultTitle} titleTemplate="%s | Feedback Fish">
      <title>{title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      ></meta>
      <script async defer src="https://cdn.splitbee.io/sb.js"></script>
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta name="twitter:card" content="summary_large_image" />
      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </Helmet>
  );
};

export default SEO;
