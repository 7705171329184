/* @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import { ReactComponent as Chevron } from "../../static/chevron.svg";
import Link from "./Link";

export const Button: React.FC<{
  primary?: boolean;
  disabled?: boolean;
  className?: string;
  href: string;
  onClick?: (evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  chevron?: boolean;
  type?: string;
}> = ({
  children,
  primary,
  className,
  href,
  onClick,
  chevron = true,
  ...rest
}) => (
  <Link
    className={className}
    to={href}
    {...rest}
    onClick={onClick}
    sx={{
      fontFamily: "Roboto",
      backgroundColor: primary ? "brandBlue.2" : "transparent",
      color: primary ? "white" : "gray.9",
      border: "none",
      borderRadius: "100px",
      fontSize: [1, 2],
      px: 3,
      display: "inline-flex",
      alignItems: "center",
      py: 2,
      transition: "ease-in-out 100ms",
      transitionProperty: "background, box-shadow",
      boxShadow: "0 0 0 rgba(0, 93, 255, 0)",
      textDecoration: `none`,
      ":focus": {
        outline: "0",
        boxShadow: "0 0 0 3px rgba(0, 93, 255, 0.45)",
      },
      ":hover": {
        backgroundColor: primary ? "brandBlue.3" : "gray.2",
      },
      ":active": {
        backgroundColor: primary ? "brandBlue.6" : "gray.3",
      },
    }}
  >
    {children}
    {chevron && <Chevron sx={{ ml: 2 }} />}
  </Link>
);
