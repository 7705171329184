/* @jsx jsx */
import "tailwindcss/dist/base.min.css";
import { Grid, jsx, Text } from "theme-ui";
import { Fragment, useState } from "react";
import { Link } from "gatsby";
import { Layout } from "../components/Layout";
import { FeedbackForm } from "../../../npm/src/FeedbackForm";
import Plans from "../components/Plans";

import patternRow from "../../static/pattern-row.svg";
import largePatternRow from "../../static/large-pattern-row.svg";

import { ReactComponent as Hero } from "../../static/hero.svg";
import { ReactComponent as Arrow } from "../../static/arrow.svg";
import { ReactComponent as GrayscaleLogo } from "../../static/logo-grayscale.svg";
import { ReactComponent as Logo } from "../../static/logo.svg";
import { ReactComponent as User } from "../../static/user.svg";
import { ReactComponent as Languages } from "../../static/languages.svg";
import { ReactComponent as Screenshot } from "../../static/screenshot.svg";
import { ReactComponent as Heart } from "../../static/heart.svg";
import { ReactComponent as Play } from "heroicons/outline/play.svg";
import { ReactComponent as ArrowRight } from "heroicons/outline/arrow-narrow-right.svg";
import { Button } from "../components/Button";
import { CodeSnippet, Line, Code } from "../components/CodeSnippet";
import { useGetCurrentUserQuery } from "../graphql/queries/_generated";
import SEO from "../components/Seo";
import { H1, H2, P } from "../components/Typography";
import { Flex } from "../components/Flex";
import Modal from "../components/Modal";
import YouTube from "react-youtube";

const Feature = ({
  name,
  icon,
  description,
  link,
}: {
  name: string;
  description: string;
  link: string;
  icon: React.FC<{}>;
}) => {
  const Icon = icon;
  return (
    <Flex gap={4}>
      <Icon sx={{ height: `4em`, width: `4em`, flexShrink: 0 }} />
      <Flex flexDirection="column" gap={3}>
        <Flex flexDirection="column" gap={2}>
          <div sx={{ fontWeight: `700`, m: 0 }}>{name}</div>
          <div sx={{ m: 0, color: `#666`, lineHeight: 1.5 }}>{description}</div>
        </Flex>
        <Link
          to={link}
          sx={{
            color: `gray.6`,
            whiteSpace: `pre-wrap`,
            svg: { transition: `transform 150ms ease-in-out` },
            "&:hover svg": {
              transform: `translateX(0.25em)`,
            },
          }}
        >
          Learn more{" "}
          <ArrowRight sx={{ height: `1em`, display: `inline-block` }} />
        </Link>
      </Flex>
    </Flex>
  );
};

const FeatureSection = () => (
  <div sx={{ display: `flex`, mt: 6, mb: 3, px: 4 }}>
    <Grid gap={[4, 4, 4, 5]} columns={[1, 1, 1, 2]}>
      <Feature
        name="User Identification"
        description="Reply to your customers by attaching their email address."
        link="/help/userid"
        icon={User}
      />
      <Feature
        name="More than 40 languages"
        description="We speak the same language as your users. The widget automatically adapts."
        link="/help/localisation"
        icon={Languages}
      />
      <Feature
        name="One-click Screenshots"
        description="See what your users see with screenshots. Device information is automatically attached."
        link="/help/screenshot"
        icon={Screenshot}
      />
      <Feature
        name="Team Friendly"
        description="Bring your team on the same page. Give your colleagues access to your feedback."
        link="/help/team"
        icon={Heart}
      />
    </Grid>
  </div>
);

const HeroSection = () => {
  const [{ data }] = useGetCurrentUserQuery();
  const loggedIn = !!data?.currentUser?.email;
  const [showDemoPopup, setShowDemoPopup] = useState(false);

  return (
    <div
      sx={{
        display: "flex",
        flexDirection: ["column", "column", "row"],
        mt: 4,
        alignItems: "flex-start",
        mb: 6,
        position: "relative",
      }}
    >
      <div
        sx={{
          px: 3,
          flex: 1,
          mr: 4,
          mb: 5,
        }}
      >
        <div sx={{ mb: [5, 6], display: "flex", alignItems: "center" }}>
          <Logo sx={{ height: 8 }} />
        </div>

        <div sx={{ flex: 1 }}>
          {/* <H1>
            Easy Feedback.
            <br />
            Happy customers.
          </H1> */}
          <H1>
            Get feedback.
            <br />
            Make your customers happy.
          </H1>
          <P sx={{ maxWidth: 500, mb: 4 }}>
            Collect issues, ideas and compliments with a simple widget. Receive
            them as email. Dive deep with the dashboard.
          </P>
          <Flex alignItems="flex-start" flexDirection={["column", "row"]}>
            <Flex flexDirection="column" alignItems="center" sx={{ mr: 2 }}>
              <Button
                href={`${loggedIn ? "/app" : `/get-started`}`}
                primary
                data-splitbee-event={loggedIn ? "Go to dashboard" : "Click CTA"}
                data-splitbee-event-location="header"
              >
                {loggedIn ? `Go to dashboard` : `Get started for free`}
              </Button>
              {!loggedIn && (
                <span
                  sx={{ maxWidth: 500, mt: 1, fontSize: 0, color: `gray.5` }}
                >
                  No credit card · No time limit
                </span>
              )}
            </Flex>
            {!loggedIn && (
              <Fragment>
                {/* Desktop: Open Popup */}
                <Button
                  href="#"
                  data-splitbee-event="Open Demo Video"
                  sx={{
                    display: [`none`, `none`, `inline-flex`],
                    whiteSpace: `pre`,
                  }}
                  chevron={false}
                  onClick={(evt) => {
                    evt.preventDefault();
                    setShowDemoPopup(true);
                  }}
                >
                  <Play height="1.5em" /> Watch the demo{" "}
                  <span sx={{ color: `gray.5` }}>15s</span>
                </Button>
                <Modal
                  isOpen={showDemoPopup}
                  onDismiss={() => setShowDemoPopup(false)}
                >
                  <YouTube
                    videoId="VzT2Thd0asc"
                    opts={{
                      width: `100%`,
                      height: `405px`,
                      playerVars: {
                        // NOTE: Need to specify playlist to the same video ID to make loop work.
                        playlist: "VzT2Thd0asc",
                        loop: 1,
                        autoplay: 1,
                        controls: 0,
                        modestbranding: 1,
                      },
                    }}
                  />
                </Modal>
                {/* Mobile: Link */}
                <Button
                  data-splitbee-event="Open Demo Video"
                  sx={{ display: [`inline-flex`, `inline-flex`, `none`] }}
                  href="https://youtube.com/watch?v=VzT2Thd0asc"
                >
                  Watch demo (15s)
                </Button>
              </Fragment>
            )}
          </Flex>
        </div>
      </div>

      <Hero sx={{ flex: 1, display: ["none", "none", "block"] }} />
      <div
        sx={{
          backgroundImage: `url(${largePatternRow})`,
          height: "94px",
          my: 4,
          display: ["block", "block", "none"],
          width: "100%",
        }}
      ></div>
    </div>
  );
};

const WidgetSection = () => {
  const [key, setKey] = useState(0);
  return (
    <div
      sx={{
        mt: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div sx={{ mb: 4, textAlign: "center", position: "relative" }}>
        <H2>Meet the widget</H2>
        <P>It's friendly, fast and lightweight.</P>
        <Arrow sx={{ position: "absolute", left: [-4, -5], bottom: -10 }} />
      </div>
      <div
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          position: "relative",
          flexDirection: "column",
          height: 270,
        }}
      >
        <div
          sx={{
            backgroundColor: "brandOrange.3",
            boxShadow: "0 0 0 75px rgba(255, 106, 0, 0.2)",
            width: "100%",
            position: "absolute",
            borderTopLeftRadius: "1000px",
            borderTopRightRadius: "1000px",
            maxWidth: "450px",
            height: "225px",
            bottom: 0,
          }}
        ></div>
        <div
          sx={{
            position: "relative",
            mb: 5,
          }}
        >
          <FeedbackForm
            projectId="f7c9b9b0b1002f"
            key={key}
            onClose={() => setKey(key + 1)}
          />
        </div>
      </div>
    </div>
  );
};

const IntegrationSection = () => (
  <div
    sx={{
      backgroundColor: "brandBlue.6",
      py: 5,
      px: [3, 5],
      position: "relative",
      color: "white",
      borderBottomLeftRadius: [0, 55],
      borderTopRightRadius: [0, 55],
    }}
  >
    <H2 sx={{ mt: 0 }}>Easy to Integrate</H2>
    <P>
      Less than 5 lines of code. Choose a button and we'll make it collect
      feedback.
    </P>
    <div
      sx={{
        display: "grid",
        gridColumn: "auto",
        gap: 3,
        mt: 3,
        gridTemplateColumns: ["1fr", null, null, "repeat(2, minmax(0, 1fr))"],
      }}
    >
      <CodeSnippet>
        <Line number={1}>
          <Code color="teal">{"<!--HTML-->"}</Code>
        </Line>
        <Line number={2}>
          <Code color="gray">{"<"}</Code>
          <Code color="red" offset={1}>
            {"script"}
          </Code>
          <Code color="orange">{"src"}</Code>
          <Code color="gray">{"="}</Code>
          <Code color="green" offset={1}>
            "https://feedback.fish/ff.js?pid=..."
          </Code>
          <Code color="orange">{"defer"}</Code>
          <Code color="gray">{">"}</Code>
          <Code color="gray">{"</"}</Code>
          <Code color="red" offset={1}>
            {"script"}
          </Code>
          <Code color="gray">{">"}</Code>
        </Line>
        <Line number={3}>
          <Code color="gray">{"<"}</Code>
          <Code color="red" offset={1}>
            {"button"}
          </Code>
          <Code color="orange">{"data-feedback-fish"}</Code>
          <Code color="gray">{">"}</Code>
        </Line>
        <Line number={4}>
          <Code color="white" inset={2}>
            Give Feedback
          </Code>
        </Line>
        <Line number={5}>
          <Code color="gray">{"</"}</Code>
          <Code color="red">{"button"}</Code>
          <Code color="gray">{">"}</Code>
        </Line>

        <Line number={6} />
      </CodeSnippet>
      <CodeSnippet>
        <Line number={1}>
          <Code color="teal">// React</Code>
        </Line>
        <Line number={2}>
          <Code color="purple" offset={1}>
            {"import"}
          </Code>
          <Code color="gray" offset={1}>
            {"{"}
          </Code>
          <Code color="red" offset={1}>
            FeedbackForm
          </Code>
          <Code color="gray" offset={1}>
            {"}"}
          </Code>
          <Code color="purple" offset={1}>
            {"from"}
          </Code>
          <Code color="green">'feedback-fish'</Code>
        </Line>
        <Line number={3}>
          <Code color="gray">{"<"}</Code>
          <Code color="yellow" offset={1}>
            {"FeedbackForm"}
          </Code>
        </Line>
        <Line number={4}>
          <Code color="orange" inset={2}>
            triggerComponent
          </Code>
          <Code color="teal">=</Code>
          <Code color="red">{"{"}</Code>
          <Code color="gray">Button</Code>
          <Code color="red">{"}"}</Code>
        </Line>
        <Line number={5}>
          <Code color="orange" inset={2}>
            projectId
          </Code>
          <Code color="teal">=</Code>
          <Code color="red">{"{"}</Code>
          <Code color="gray">...</Code>
          <Code color="red" offset={1}>
            {"}"}
          </Code>
        </Line>
        <Line number={6}>
          <Code color="gray">{"/>"}</Code>
        </Line>
      </CodeSnippet>
    </div>
  </div>
);

const PricingSection = () => (
  <div sx={{ textAlign: "center", mt: 6, mb: 5 }}>
    <H2 id="pricing">Pricing</H2>
    <P>Simple widget, even simpler pricing.</P>
  </div>
);

const CallToActionSection = () => (
  <div
    sx={{
      textAlign: "center",
      maxWidth: 400,
      mx: "auto",
      my: 6,
      lineHeight: 1.5,
    }}
  >
    <H2 sx={{ marginBottom: 4 }}>
      Ready for your
      <br />
      users to get heard?
    </H2>
    <Button
      primary
      href={`/get-started`}
      data-splitbee-event="Click CTA"
      data-splitbee-event-location="footer"
    >
      Get started for free
    </Button>
  </div>
);

const TwitterLink = ({
  username,
  name,
}: {
  username: string;
  name: string;
}) => {
  return (
    <a sx={linkStyle} href={`https://twitter.com/${username}`}>
      {name}
    </a>
  );
};

const linkStyle = {
  textDecoration: "none",
  color: "gray.8",
  ":hover": { textDecoration: "underline" },
};

export const Footer: React.FC<{ minimal: boolean }> = ({ minimal }) => (
  <footer sx={{ px: [0, 3] }}>
    {!minimal && (
      <div
        sx={{
          height: "47px",
          mt: 6,
          maxWidth: "1269px",
          backgroundImage: `url(${patternRow})`,
          width: "100%",
        }}
      ></div>
    )}
    <div
      sx={{ px: [3, 0], pt: 4, pb: 4, fontSize: 1, display: [null, "flex"] }}
    >
      <div
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          sx={{
            display: ["flex", "none"],
            justifyContent: "center",
            flexDirection: "row",
            mr: 3,
          }}
        >
          <GrayscaleLogo sx={{ width: 10 }} />
        </div>
        <div
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div sx={{ fontSize: 1, fontWeight: "600" }}>Feedback Fish</div>
          <div>© {new Date().getFullYear()}</div>
        </div>
      </div>
      <div
        sx={{
          flex: 1,
          textAlign: ["initial", "center"],
          fontSize: 0,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          my: [3, null],
        }}
      >
        <Flex
          flexDirection={["column", "row"]}
          sx={{ margin: ["0", "auto"], my: [2, 0] }}
        >
          <Link sx={{ ...linkStyle, mr: [0, 4], mb: [1, 0] }} to="/help/tos">
            Terms of Service
          </Link>
          <Link sx={linkStyle} to="/help/privacy">
            Privacy Policy
          </Link>
        </Flex>
        <div sx={{ color: "gray.6" }}>
          Built in Vienna by <TwitterLink name="Max" username="mxstbr" />,{" "}
          <TwitterLink name="Tobi" username="linstobias" />,{" "}
          <TwitterLink name="Timo" username="timolins" /> and{" "}
          <TwitterLink name="Laurids" username="lauridskern" />
        </div>
      </div>
      <div
        sx={{
          display: ["none", "flex"],
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <GrayscaleLogo sx={{ width: 10 }} />
      </div>
    </div>
  </footer>
);

export default function Home() {
  return (
    <Layout disableHeader disablePadding wide>
      <SEO />
      <HeroSection />
      <WidgetSection />
      <IntegrationSection />
      <FeatureSection />
      <PricingSection />
      <Plans />
      <CallToActionSection />
    </Layout>
  );
}
