/* @jsx jsx */
import { jsx } from "theme-ui";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import useOnclickOutside from "react-cool-onclickoutside";

interface OverlayTriggerProps {
  renderTrigger: (
    triggerProps: { onClick: () => void },
    open: boolean
  ) => JSX.Element;
  renderContent: (closeOverlay: () => void) => JSX.Element;
}

export const OverlayTrigger = ({
  renderTrigger,
  renderContent,
}: OverlayTriggerProps) => {
  const [open, setOpen] = useState(false);
  const ref = useOnclickOutside(() => {
    setOpen(false);
  });

  return (
    <div sx={{ position: "relative" }}>
      {renderTrigger(
        {
          onClick: () => {
            setOpen(!open);
          },
        },
        open
      )}
      <AnimatePresence>
        {open && (
          <motion.div
            role="menu"
            ref={ref}
            aria-orientation="vertical"
            aria-labelledby="category-menu"
            initial={{
              opacity: 0,
              scale: 0.98,
              y: -8,
              originX: 1,
              originY: 0,
            }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 0.98, y: -8 }}
            transition={{ duration: 0.18 }}
            sx={{
              bg: "white",
              borderRadius: 6,
              display: "flex",
              width: "100%",
              justifyContent: "center",
              flexDirection: "column",
              mt: 2,
              overflow: "hidden",
              border: "1px solid",
              borderColor: "gray.2",
              position: "absolute",
              boxShadow: "xl",
            }}
          >
            {renderContent(() => {
              setOpen(!open);
            })}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
