const useGraphCDN = () =>
  true; /* || !!global?.window?.localStorage.getItem("use-graphcdn"); */

export default {
  backendUrl:
    process.env.NODE_ENV === `development`
      ? `http://localhost:4321`
      : `https://api.feedback.fish`,
  graphqlUrl:
    process.env.NODE_ENV === `development`
      ? `http://localhost:4321/graphql`
      : useGraphCDN()
      ? `https://graphcdn.api.feedback.fish`
      : `https://api.feedback.fish/graphql`,
};
