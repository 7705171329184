/* @jsx jsx */
import * as React from "react";
import { jsx } from "theme-ui";

export const H1: React.FC<{ className?: string }> = ({
  children,
  className,
}) => (
  <h1
    className={className}
    sx={{
      fontFamily: "PT Serif",
      fontWeight: "bold",
      lineHeight: 1.25,
      mb: 3,
      fontSize: [5, 6, 6, 7],
    }}
  >
    {children}
  </h1>
);

export const H2: React.FC<{
  className?: string;
  id?: string;
}> = ({ children, className, id }) => (
  <h2
    className={className}
    id={id}
    sx={{
      fontFamily: "PT Serif",
      fontWeight: "bold",
      lineHeight: 1.25,
      fontSize: [4, 5],
      mb: 2,
    }}
  >
    {children}
  </h2>
);

export const P: React.FC<{
  className?: string;
}> = ({ children, className }) => (
  <p
    className={className}
    sx={{
      fontSize: 2,
      fontWeight: "400",
      fontFamily: "Roboto",
      mt: 1,
      mb: 2,
      color: "gray.5",
    }}
  >
    {children}
  </p>
);
