type Splitbee = {
  track: Function;
  user: {
    set: Function;
  };
};

export const splitbee = (): undefined | Splitbee => {
  if (
    typeof global.window === `undefined` ||
    typeof (global.window as any).splitbee === `undefined`
  )
    return;

  return (global.window as any).splitbee;
};
